import React from 'react';
import Container from '@material-ui/core/Container';

const FourOhFour = () => (
  <Container>
    {'404'}
  </Container>
);

export default FourOhFour;
